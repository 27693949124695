export default {
  props: {
    title: {
      type: String
    },
    availableTitles: {
      type: Array
    }
  },
  data: () => ({
    titleDialog: false,
    actions: [],
    colsPerButton: 0,
    additionalData: [],
    selectedTitle: ''
  }),
  methods: {
    async open(actions, additionalData = []) {
      // reset title to current one
      this.selectedTitle = this.title;
      this.actions = actions;
      this.titleDialog = true;
      this.colsPerButton = Math.floor(12 / actions.length);
      this.additionalData = additionalData;
    },
    actionPerformed(action) {
      const title = this.$refs.titleCombobox.$data.lazySearch;
      this.$emit('actionPerformed', action, title);
      this.titleDialog = false;
    }
  }
};