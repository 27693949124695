import TaskService from '@/services/task/taskService.js';
export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    statusDialog: false,
    statusMessage: '',
    actions: [],
    colsPerButton: 0,
    additionalData: [],
    states: [],
    selectedStatus: {
      key: undefined,
      description: undefined,
      color: undefined,
      done: undefined
    },
    selectedStatusKey: ''
  }),
  methods: {
    async open(statusMessage, actions, additionalData = []) {
      await TaskService.getStates().then(states => {
        this.states = states;
      });

      // reset status to current one
      this.selectedStatusKey = this.task.status.key;
      this.selectedStatus = {
        key: undefined,
        description: undefined,
        color: undefined,
        done: undefined
      };
      this.statusMessage = statusMessage;
      this.actions = actions;
      this.statusDialog = true;
      this.colsPerButton = Math.floor(12 / actions.length);
      this.additionalData = additionalData;
    },
    actionPerformed(action) {
      this.$emit('actionPerformed', action, this.selectedStatus);
      this.statusDialog = false;
    },
    statusChanged(status) {
      let statusIndex = -1;
      this.states.forEach((item, index) => {
        if (item.key.lfdnr == status.lfdnr) {
          statusIndex = index;
        }
      });
      this.selectedStatus = this.states[statusIndex];
    }
  }
};