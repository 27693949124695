import axios from 'axios'

export default {
  async editTitle(taskId, title) {
    const response = await axios.patch(
      `/v1/task/${taskId}/title?value=${title}`
    )

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getTitles() {
    const response = await axios.get('v1/task-resources/titles')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async editDescription(taskId, text) {
    const response = await axios.patch(`/v1/task/${taskId}/description`, text, {
      headers: {
        'Content-Type': 'text/plain'
      }
    })

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async editDueDate(taskId, dueDate) {
    const response = await axios.patch(
      `/v1/task/${taskId}/due-date?value=${dueDate}T00:00:00`
    )

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async getStates() {
    const response = await axios.get('/v1/task-resources/states')

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async editStatus(taskId, status) {
    const response = await axios.patch(`/v1/task/${taskId}/state`, status)

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async editNotificationState(taskId, notificationsState) {
    const response = await axios.patch(
      `/v1/task/${taskId}/notifications-active?value=${notificationsState}`
    )

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async addComment(taskId, text) {
    await axios
      .put('/v1/task-comments', {
        taskId,
        text
      })
      .then(response => {
        return response.data
      })
  },

  async editComment(taskId, lfdnr, text) {
    const response = await axios.put('/v1/task-comments', {
      taskId,
      lfdnr,
      text
    })

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async deleteComment(taskCommentId) {
    const response = await axios.delete('/v1/task-comments/' + taskCommentId)

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  },

  async deleteTask(taskId) {
    const response = await axios.delete('/v1/task/' + taskId)

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  }
}
