import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "width": "650"
    },
    model: {
      value: _vm.titleDialog,
      callback: function ($$v) {
        _vm.titleDialog = $$v;
      },
      expression: "titleDialog"
    }
  }, [_c(VCard, [_c(VToolbar, {
    attrs: {
      "color": "primary",
      "dark": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.titleDialog = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v(" Titel bearbeiten ")])], 1), _c(VContainer, {
    staticClass: "pa-4"
  }, [_c(VRow, {
    staticClass: "mx-2 mt-4"
  }, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VCombobox, {
    ref: "titleCombobox",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Titel",
      "items": _vm.availableTitles
    },
    model: {
      value: _vm.selectedTitle,
      callback: function ($$v) {
        _vm.selectedTitle = $$v;
      },
      expression: "selectedTitle"
    }
  })], 1)], 1), _c(VRow, _vm._l(_vm.actions, function (action) {
    return _c(VCol, {
      key: action.action,
      staticClass: "d-flex flex-column",
      attrs: {
        "cols": "colsPerButton"
      }
    }, [_c(VBtn, {
      staticClass: "warning--text",
      attrs: {
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.actionPerformed(action.action);
        }
      }
    }, [_c(VIcon, {
      staticClass: "mr-1"
    }, [_vm._v(_vm._s(action.icon))]), _vm._v(" " + _vm._s(action.text) + " ")], 1)], 1);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };