import TaskDetail from './TaskDetail.vue';
import TaskDetailMobile from './TaskDetailMobile.vue';
export default {
  components: {
    TaskDetail,
    TaskDetailMobile
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  computed: {
    width: function () {
      return window.innerWidth;
    },
    isMobile() {
      var breakpoint = this.$vuetify.breakpoint.name;
      if (['xs', 'sm'].includes(breakpoint)) return true;
      return false;
    }
  }
};